.page-footer {
  @include container;
  @include slash($day, 60%, 0);
  background-color: $darkgreen;
  color: $day;
  flex-flow: row wrap;
  justify-content: space-between;
  min-height: 50vmin;
  padding-bottom: 2rem;

  a {
    color: $day;
  }

  &__meta {
    @include box;
    @include text-slash-l($green);
    display: flex;
    flex-flow: column nowrap;
    width: 32rem;

    &__text {
    }

    &__menu {
      margin-top: auto;
      padding-top: 2rem;

      ul {
        align-items: flex-end;
        display: flex;
      }

      li {
        margin-right: 2rem;
      }

      a {
        color: $green;
        font-weight: 600;
        transition: color ease-in-out .2s;
      }

      a:hover {
        color: $day;
      }
    }
  }

  &__contact {
    @include box;
    align-self: flex-end;
    width: 16rem;

    @media #{$mq-m} {
      margin-left: auto;
    }

    &__logo {
      background-image: url('../images/logo.svg');
      background-position: top left;
      background-size: cover;
      display: block;
      height: 3rem;
      text-indent: -999rem;
      width: 8.25rem;
    }

    &__text {
      @include text-slash-s($blue);
      margin-top: 2rem;
    }
  }
}
