.main-title {
  @include container;
  flex-flow: row wrap;
  justify-content: flex-end;

  h1 {
    @include box;
    @include text-slash-l($turquoise);
    width: 100%;

    @media #{$mq-m} {
      width: 50%;
    }
  }

  &__intro {
    @include box;
    width: 100%;

    @media #{$mq-m} {
      width: 50%;
    }
  }

  .page--latest &,
  .page--news &,
  .page--event &,
  .page--pressrelease & {
    background-color: $darkblue;
    color: $day;
  }

  .page--members &,
  .page--default &,
  .page--law &,
  .page--story & {
    background-color: $darkgreen;
    color: $day;
  }
}
