.cookie {
  align-items: flex-start;
  background-color: $darkblue;
  bottom: 0;
  color: $day;
  display: flex;
  filter: drop-shadow(0 0 4rem rgba($night, .375));
  flex-flow: column nowrap;
  left: 0;
  padding: 2rem;
  position: fixed;
  width: 100%;
  z-index: 100000;

  @supports (position: sticky) {
    position: sticky;
  }

  @media #{$mq-m} {
    padding: 2rem 4rem;
  }

  &__info {
    font-size: .875rem;
    max-width: 42rem;
    width: 100%;

    a {
      border-bottom: .0625rem $day solid;
      color: $day;
    }
  }

  &__actions {
    display: flex;
    margin-top: 1rem;
    width: 100%;
  }

  &__btn {
    border-radius: .5rem;
    color: $day;
    cursor: pointer;
    font-size: .875rem;
    font-weight: 600;
    line-height: 1;
    margin-right: 1rem;
    padding: .875rem .875rem .75rem;
    text-align: center;

    &--accept {
      background-color: $blue;
      color: $day;
      transition: background-color ease-in-out .2s;
    }

    &--accept:hover {
      background-color: $green;
    }

    &--reject {
      background: none;
      border: .125rem $blue solid;
      color: $blue;
    }

    &--reject:hover {
      border-color: $day;
      color: $day;
      transition: border-color ease-in-out .2s, color ease-in-out .2s;
    }
  }
}
