.downloads {
  @include container;
  @include slash($turquoise, 80%, 0);
  background-color: $green;
  flex-flow: row wrap;

  &__title {
    @include box;
    @include text-slash-l($darkblue);
    flex: 0 0 auto;
    width: 100%;

    h2 {
      max-width: 32rem;
    }
  }

  &__file {
    @include box;
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    flex-flow: column nowrap;
    width: 100%;

    @media #{$mq-s} {
      flex-flow: row nowrap;
    }

    @media #{$mq-m} {
      margin-left: auto;
      width: 75%;
    }

    @media #{$mq-l} {
      margin-left: 0;
      width: 50%;
    }

    img {
      border-radius: 1rem;
      display: block;
      flex: 0 0 auto;
      height: auto;
      width: 8rem;
    }

    div {
      margin-top: 2rem;

      @media #{$mq-s} {
        margin-left: 2rem;
        margin-top: 0;
      }
    }

    strong {
      display: block;
    }

    small {
      display: block;
      font-family: $ff-serif;
      margin-top: 1rem;
    }
  }
}
