.title {
  @include container;
  align-items: flex-end;
  min-height: calc(50vmin - 5rem);

  @media #{$mq-m} {
    min-height: calc(50vmin - 7rem);
  }

  h1 {
    @include box;
    @include text-slash-l($turquoise);
    margin-left: auto;
    width: 36rem;
  }
}
