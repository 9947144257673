.default {
  @include container;
  @include slash($turquoise, 40%, 0);
  flex-flow: column nowrap;

  @media #{$mq-m} {
    flex-flow: row nowrap;
  }

  &__title {
    @include box;
    @include text-slash-l($green);
    width: 50%;
  }

  &__text {
    @include box;
    overflow: hidden;
    width: 50%;
  }
}
