@mixin container {
  position: relative;
  display: flex;
  padding: 4rem 1rem;

  @media #{$mq-s} {
    padding: 4rem;
  }

  @media #{$mq-m} {
    padding: 4rem 6rem;
  }
}

@mixin box {
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
}
