.page-menu {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 2rem 2rem 0;
  width: 100%;

  @media #{$mq-m} {
    padding: 4rem 4rem 0;
  }

  &__logo {
    background-image: url('../images/logo.svg');
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 23rem 3rem;
    display: block;
    flex: 0 0 auto;
    height: 3rem;
    text-indent: -999rem;
    width: 8.25rem;

    @media #{$mq-s} {
      width: 23rem;
    }
  }

  &__links {
    display: flex;
    flex-flow: column nowrap;
    font-weight: 600;
    text-align: right;

    @media #{$mq-l} {
      flex-flow: row nowrap;
    }

    li {
      margin-top: .5rem;

      @media #{$mq-l} {
        margin-left: 2rem;
        margin-top: 0;
      }
    }

    li:first-child {
      margin-top: 0;
    }

    a {
      transition: color ease-in-out .2s;

      &.active {
        border-bottom: .125rem solid $blue;
      }
    }

    a:hover {
      color: $blue;
    }
  }

  .page--latest &,
  .page--news &,
  .page--event &,
  .page--pressrelease & {
    background-color: $darkblue;

    .page-menu__logo {
      background-image: url('../images/logo--alternate.svg');
    }

    a {
      color: $day;
    }

    a:hover {
      color: $blue;
    }
  }

  .page--members &,
  .page--default &,
  .page--law &,
  .page--story & {
    background-color: $darkgreen;

    .page-menu__logo {
      background-image: url('../images/logo--alternate.svg');
    }

    a {
      color: $day;
    }

    a:hover {
      color: $blue;
    }
  }
}
