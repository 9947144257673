.about {
  @include slash($turquoise, 50%, 0);
  background-color: $darkblue;
  color: $day;

  &__intro {
    @include container;
    flex-flow: row wrap;

    &__title {
      @include box;
      @include text-slash-l($green);
      width: 100%;

      @media #{$mq-l} {
        margin-left: auto;
      }
    }

    &__text {
      @include box;
      width: 100%;

      ul {
        margin-top: 1rem;
        display: flex;
        flex-flow: row wrap;
      }

      li {
        width: 100%;
        margin-top: 1rem;

        @media #{$mq-m} {
          width: 33.333%;
          padding-right: 2rem;
        }
      }
    }
  }

  &__topics {
    @include container;
    border-top: .0625rem $green dashed;
    flex-flow: row wrap;
    justify-content: space-between;

    &__description {
      @include box;
      font-family: $ff-serif;
      font-size: 1.25rem;
      width: 100%;
      z-index: 2;

      p {
        hyphens: none;
      }
    }

    &__list {
      @include box;
      counter-reset: li;
      width: 100%;
      z-index: 2;

      h3:nth-of-type(3n+1) {
        color: $blue;
      }

      h3:nth-of-type(3n+2) {
        color: $green;
      }

      h3:nth-of-type(3n+3) {
        color: $turquoise;
      }

      ul {
        display: flex;
        flex-flow: row wrap;
        font-size: 1rem;
        margin-top: -1rem;
        max-width: 42rem;

        @media #{$mq-s} {
          font-size: 1.125rem;
        }
      }

      li {
        margin-top: 1rem;
        counter-increment: li;
        display: flex;
      }

      li::before {
        content: counter(li) '. ';
        display: block;
        min-width: 2rem;
        padding-left: .125rem;
      }

      li span {
        display: block;
      }
    }

    &__image {
      background-image: url('../images/Kreuzung_opt.jpg');
      background-position: center center;
      background-size: cover;
      display: block;
      filter: grayscale(100%);
      height: 100%;
      left: 0;
      opacity: .1;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }
}
