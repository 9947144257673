.text {
  @include container;
  @include slash($green, 40%, -40deg);

  &__content {
    @include box;

    > * {
      max-width: 48rem;
    }
  }

  ul,
  ol {
    display: block;
    font-family: $ff-serif;
    hyphens: auto;
    margin-bottom: 2rem;
    margin-top: 2rem;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  li {
    border-top: .0625rem $turquoise dashed;
    font-size: 1.0625rem;
    margin-top: 1rem;
    padding-top: 1rem;

    &:first-child {
      margin-top: 0;
    }

    ol > & {
      list-style-type: decimal-leading-zero;
      padding-left: 1.5rem;
    }
  }

  dl {
    display: flex;
    flex-flow: column nowrap;
    font-family: $ff-serif;
    font-size: 1.0625rem;
    margin-bottom: 2rem;
    margin-top: 2rem;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    @media #{$mq-s} {
      flex-flow: row wrap;
    }

    dt {
      border-top: .0625rem $turquoise dashed;
      font-weight: 600;
      margin-top: 1rem;
      padding-top: 1rem;

      @media #{$mq-s} {
        width: 20%;
      }
    }

    dd {
      margin-top: .5rem;

      @media #{$mq-s} {
        border-top: .0625rem $turquoise dashed;
        margin-top: 1rem;
        padding-top: 1rem;
        width: 80%;
      }
    }
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 2rem;
    margin-top: 4rem;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    @media #{$mq-s} {
      font-size: 1.75rem;
    }
  }

  h3 {
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 2rem;

    @media #{$mq-s} {
      font-size: 1.25rem;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    border-bottom: .0625rem solid $turquoise;
  }

  figure {
    margin-bottom: 4rem;
    margin-top: 4rem;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    img {
      display: block;
      height: auto;
      width: 100%;
    }

    &.no-fullwidth img {
      max-width: 100%;
      width: auto;
    }

    figcaption {
      font-weight: 600;
      margin-top: 1rem;
    }
  }
}
