.popup {
  display: block;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10000;

  &.active {
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: auto;
    pointer-events: all;

    > div > section {
      transform: translateX(0);
    }
  }

  > div {
    align-items: center;
    min-height: 100vh;
    width: 100%;

    > section {
      min-height: 100vh;
      pointer-events: all;
      position: relative;
      transform: translateX(+100%);
      transition: transform ease-in-out 1s;
      z-index: 10001;

      @media #{$mq-m} {
        margin-left: auto;
        max-width: 44rem;
      }
    }
  }

  &__close {
    color: $green;
    cursor: pointer;
    display: block;
    font-family: $ff-sans;
    font-size: .875rem;
    font-weight: 600;
    max-width: 4rem;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    right: 2rem;
    text-align: right;
    top: 2rem;
    transition: opacity ease-in-out .2s;
    transition-delay: 1s;
    z-index: 10002;

    .popup.active & {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__overlay {
    background-color: $night;
    display: block;
    height: 100vh;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: opacity ease-in-out .75s;
    width: 100vw;
    z-index: 1000;

    .popup.active & {
      opacity: .65;
    }
  }
}
