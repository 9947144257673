.latest {
  @include container;
  @include slash($green, 40%, -40deg);
  flex-flow: row wrap;

  &--members {
    @include slash($blue, 80%, 0);
    background-color: $green;
  }

  &__entry {
    @include text-slash-l($blue);
    @include box;
    flex: 0 0 auto;
    width: 100%;

    @media #{$mq-m} {
      width: 50%;
    }

    &__btn {
      background-color: $darkblue;
      border-radius: .5rem;
      color: $day;
      display: inline-block;
      font-weight: 600;
      line-height: 1;
      margin-top: 2rem;
      min-width: 10rem;
      padding: 1.125rem .875rem .875rem;
      text-align: center;
      transition: background-color ease-in-out .2s;
    }

    &__btn:hover {
      background-color: $green;
    }
  }

  &--members &__entry {
    &__btn:hover {
      background-color: $turquoise;
    }
  }
}
