.service {
  @include container;
  @include slash($green, 40%, -40deg);
  background-color: $blue;
  color: $day;
  flex-flow: row wrap;

  &__title {
    @include box;
    @include text-slash-l($turquoise);
    flex: 0 0 auto;
    width: 100%;

    h2 {
      max-width: 20rem;
    }
  }

  &__text {
    @include box;
    flex: 0 0 auto;
    width: 100%;

    @media #{$mq-m} {
      width: 50%;
    }

    &__toggle {
      background-color: $darkblue;
      border-radius: .5rem;
      color: $day;
      cursor: pointer;
      display: inline-block;
      font-weight: 600;
      line-height: 1;
      margin-top: 2rem;
      min-width: 10rem;
      padding: 1.125rem .875rem .875rem;
      text-align: center;
      transition: background-color ease-in-out .2s;
    }

    &__toggle:hover {
      background-color: $turquoise;
    }
  }
}
