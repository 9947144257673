@mixin text-slash-l($color) {
  > .slash {
    height: 3rem;
    left: -.125rem;
    position: absolute;
    top: .28125rem;
    width: 1.5rem;

    @media #{$mq-s} {
      height: 4rem;
      left: -.875rem;
      top: .375rem;
      width: 2rem;
    }

    path {
      fill: $color;
    }
  }
}

@mixin text-slash-s($color) {
  > .slash {
    display: block;
    height: auto;
    left: -1.5rem;
    position: absolute;
    top: .1875rem;
    width: 1rem;

    path {
      fill: $color;
    }
  }
}

@mixin slash($color, $left, $rotate) {
  > .slash {
    height: 8rem;
    left: $left;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%) rotate($rotate);
    width: 4rem;
    z-index: 100;

    @media #{$mq-l} {
      height: 10rem;
      width: 5rem;
    }

    path {
      fill: $color;
    }
  }
}
