.fact {
  @include container;
  color: $day;
  flex-flow: column nowrap;
  min-height: 100vh;

  &--first {
    @include slash($green, 20%, -40deg);
  }

  &--second {
    @include slash($blue, 40%, -40deg);
  }

  &--third {
    @include slash($turquoise, 40%, -40deg);
  }

  &__title {
    @include box;
    align-items: flex-end;
    display: flex;
    justify-content: center;
    min-height: calc(50vh - 8rem);
    z-index: 3;

    h2 {
      font-size: calc(2rem + 5vw);
      font-weight: 600;
      line-height: 1;
      text-align: center;

      @media #{$mq-l} {
        font-size: 6rem;
      }
    }

    &--single {
      margin-bottom: auto;
      margin-top: auto;
      min-height: 0;

      h2 {
        font-size: calc(1rem + 5vw);

        @media #{$mq-l} {
          font-size: 4rem;
        }
      }
    }
  }

  &__text {
    @include box;
    @include text-slash-l($day);
    margin-top: auto;
    max-width: 36rem;
    z-index: 3;

    @media #{$mq-m} {
      margin-left: auto;
    }

    strong {
      display: block;

      & + p {
        margin-top: 0;
      }
    }

    cite {
      display: block;
      font-size: .75rem;
      font-weight: 600;
      margin-top: 2rem;
    }
  }

  &__image {
    background-position: center center;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;

    .fact--first & {
      background-image: url('../images/Unfall_opt.jpg');
    }

    .fact--second & {
      background-image: url('../images/Strasse_opt.jpg');
    }

    .fact--third & {
      background-image: url('../images/LKW_opt.jpg');
    }
  }

  &::after {
    background: $shadow-gradient;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }
}
