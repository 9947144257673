.gallery {
  display: block;
  margin-top: 2rem;
  max-width: 100%;
  width: 100%;

  @media #{$mq-s} {
    column-count: 2;
    column-gap: 2rem;
  }

  @media #{$mq-l} {
    column-count: 3;
  }

  &__image {
    break-inside: avoid;
    padding-top: 2rem;
    page-break-inside: avoid;

    img {
      display: block;
      height: auto;
      width: 100%;
    }
  }
}
