.members {
  @include slash($turquoise, 40%, -40deg);
  background-color: $day;
  color: $darkblue;
  display: block;

  &__board {
    @include container;
    flex-flow: row wrap;
  }

  &__title {
    @include box;
    @include text-slash-l($darkgreen);
    flex: 0 0 auto;
    width: 100%;

    h2 {
      max-width: 20rem;
    }
  }

  &__subtitle {
    @include box;
    @include text-slash-l($green);
    flex: 0 0 auto;
    width: 100%;

    h2 {
      max-width: 20rem;
    }
  }

  &__person {
    @include box;
    display: flex;
    flex: 0 0 auto;
    flex-flow: column nowrap;
    width: 100%;

    @media #{$mq-s} {
      align-items: flex-end;
      flex-flow: row nowrap;
    }

    @media #{$mq-l} {
      width: 50%;
    }

    img {
      border-radius: 1rem;
      display: block;
      height: 8rem;
      width: 8rem;
    }

    figcaption {
      margin-top: 2rem;

      @media #{$mq-s} {
        margin-left: 2rem;
        margin-top: 0;
      }
    }

    strong {
      display: block;
      margin-bottom: 1rem;
    }

    a {
      font-family: $ff-serif;
    }
  }

  &__more {
    @include container;
    border-top: .0625rem $darkblue dashed;
    flex-flow: column nowrap;
  }

  &__logos {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    padding: 0 1rem;
    width: 100%;

    li {
      @include box;
      margin-bottom: 1rem;
      margin-top: 1rem;
      padding-left: 2rem;
      padding-right: 2rem;
      position: relative;
    }

    a {
      display: block;
    }

    img {
      height: auto;
      max-height: 4rem;
      max-width: 8rem;
      width: auto;
    }
  }
}
