@font-face {
  font-family: 'Gineso-Ext';
  src: url('../fonts/373E89_1_0.eot'),
       url('../fonts/373E89_1_0.eot?#iefix') format('embedded-opentype'),
       url('../fonts/373E89_1_0.woff') format('woff'),
       url('../fonts/373E89_1_0.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Gineso-Ext';
  src: url('../fonts/373E89_0_0.eot'),
       url('../fonts/373E89_0_0.eot?#iefix') format('embedded-opentype'),
       url('../fonts/373E89_0_0.woff') format('woff'),
       url('../fonts/373E89_0_0.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Tisa';
  src: url('../fonts/tisa400.eot'),
       url('../fonts/tisa400.eot?#iefix') format('embedded-opentype'),
       url('../fonts/tisa400.woff') format('woff'),
       url('../fonts/tisa400.ttf') format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}
