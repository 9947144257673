.page-contact {
  @include container;
  @include slash($blue, 40%, -40deg);
  align-items: center;
  background-color: $darkblue;
  color: $day;
  justify-content: center;
  min-height: 100vh;

  &__heading {
    @include box;
    @include text-slash-l($day);
    width: 36rem;
    z-index: 2;

    a {
      color: $day;
    }

    p {
      margin-top: 2rem;
    }

    &__cta {
      display: flex;
      flex-flow: row wrap;
      margin-top: 2rem;

      a {
        background-color: $darkgreen;
        border-radius: .5rem;
        color: $day;
        font-weight: 600;
        line-height: 1;
        margin-right: 2rem;
        margin-top: 1rem;
        min-width: 14rem;
        padding: 1.125rem .875rem .875rem;
        text-align: center;
        transition: background-color ease-in-out .2s;
      }

      a + a {
        background-color: $darkblue;
      }

      a:hover {
        background-color: $blue;
      }
    }
  }

  &__image {
    background-size: cover;
    display: block;
    height: 100%;
    left: 0;
    opacity: .5;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}
