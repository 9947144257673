.page {
  min-width: 20rem;
  overflow-x: hidden;
  position: relative;
  z-index: 0;

  main {
    display: flex;
    flex-flow: column nowrap;
    min-width: 20rem;
  }
}
