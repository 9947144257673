.services {
  @include container;
  background-color: $day;
  flex-flow: column nowrap;

  &__title {
    @include box;
    @include text-slash-l($green);
    flex: 0 0 auto;
    width: 100%;
  }

  &__text {
    @include box;
    flex: 0 0 auto;
    width: 100%;

    ol {
      counter-reset: li;
      display: block;
      margin-top: 1rem;
    }

    li {
      border-top: .0625rem $green dashed;
      counter-increment: li;
      font-family: $ff-serif;
      hyphens: auto;
      margin-top: 1rem;
      padding-top: 1rem;
      position: relative;
    }

    li:first-of-type {
      border: 0;
    }

    li::before {
      color: $green;
      content: counter(li);
      display: block;
      font-family: $ff-sans;
      font-size: 2.5rem;
      font-weight: 600;
      left: -2.5rem;
      position: absolute;
      text-align: center;
      top: .75rem;
      width: 2rem;
    }
  }
}
