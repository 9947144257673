html {
  @include pagezoom;
}

body {
  color: $darkblue;
  font-family: $ff-sans;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
}

strong,
b {
  font-family: $ff-sans;
  font-weight: 600;
  hyphens: none;
}

em,
i {
  font-style: normal;
  font-weight: 400;
}

a {
  color: $darkblue;
}

h1 {
  font-size: 1.5rem;
  font-weight: 600;
  position: relative;

  @media #{$mq-s} {
    font-size: 2rem;
  }
}

h2 {
  font-size: 1.5rem;
  font-weight: 600;

  @media #{$mq-s} {
    font-size: 2rem;
  }

  sub {
    display: block;
    font-size: 1.125rem;
    margin-bottom: .5rem;
  }
}

h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 2rem;

  @media #{$mq-s} {
    font-size: 1.5rem;
  }

  &:first-child {
    margin-top: 0;
  }
}

p {
  font-family: $ff-serif;
  hyphens: auto;
  margin-top: 1rem;

  &:first-child {
    margin-top: 0;
  }
}
