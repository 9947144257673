.page-alerts {
  align-items: flex-end;
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  pointer-events: none;
  position: fixed;
  right: 0;
  transition: opacity ease-out .2s, transform ease-out .2s, visibility .2s;
  width: 100%;
  z-index: 1000;

  &.headroom--unpinned {
    opacity: 0;
    transform: translateY(+2rem);
    visibility: hidden;
  }

  &.headroom--pinned,
  &:focus-within {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }

  @media #{$mq-s} {
    bottom: 2rem;
    max-width: 32rem;
    right: 2rem;
  }

  @media #{$mq-m} {
    bottom: 4rem;
    right: 4rem;
  }

  &__item {
    background-color: $darkgreen;
    border-top: .0625rem $green dashed;
    color: $day;
    font-size: .875rem;
    padding: 1.5rem 2rem 1.5rem 3rem;
    pointer-events: all;
    transition: background-color ease-in-out .2s;
    width: 100%;

    &:first-child {
      border-top: 0;
    }

    &:hover,
    &:focus {
      background-color: $darkblue;
      outline: none;
    }

    &__content {
      @include text-slash-s($turquoise);
    }

    p {
      margin: 0;
    }
  }
}
