.contactform {
  display: block;
  margin-top: 4rem;
  width: 100%;

  &__alert {
    margin-bottom: 2rem;
  }

  &__field {
    margin-top: 2rem;
    position: relative;

    label {
      display: block;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    &--checkbox {
      align-items: flex-start;
      display: flex;
      flex-flow: row-reverse;

      label {
        margin-bottom: 0;
      }
    }

    input[type=text],
    input[type=email] {
      font-family: $ff-serif;
      appearance: none;
      background: none;
      border: .0625rem $turquoise dashed;
      color: $darkblue;
      display: block;
      height: 3rem;
      padding: 1rem;
      width: 100%;
    }

    input[type=checkbox] {
      border: .0625rem $turquoise dashed;
      flex: 0 0 auto;
      height: 2rem;
      margin-right: 1rem;
      width: 2rem;

      &:checked {
        background-color: $green;
      }
    }
  }

  &__btn {
    appearance: none;
    background-color: $darkblue;
    border-radius: .5rem;
    color: $day;
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    line-height: 1;
    margin-top: 2rem;
    min-width: 10rem;
    padding: 1.125rem .875rem .875rem;
    text-align: center;
    transition: background-color ease-in-out .2s;
  }

  &__btn:hover {
    background-color: $green;
  }
}
