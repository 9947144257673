$ff-serif: 'Tisa', serif;
$ff-sans: 'Gineso-Ext', sans-serif;

$blue: #0093b6;
$darkblue: #1b4161;
$green: #a2d10c;
$darkgreen: #00524e;
$turquoise: #00aaa0;

$day: #fff;
$night: #000;

$shadow-gradient: linear-gradient(180deg, rgba($night, 0) 0, rgba($night, .75) 100%);

$mq-s: 'only screen and (min-width: 40rem)';
$mq-m: 'only screen and (min-width: 60rem)';
$mq-l: 'only screen and (min-width: 80rem)';
