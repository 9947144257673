.benefits {
  @include container;
  background-color: $darkblue;
  color: $day;
  flex-flow: column nowrap;

  &__title {
    @include box;
    @include text-slash-l($green);
    flex: 0 0 auto;
    width: 100%;
  }

  &__text {
    @include box;
    flex: 0 0 auto;
    width: 100%;
  }

  &__group {
    border-top: .0625rem $green dashed;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 2rem;
    padding-top: 2rem;

    @media #{$mq-m} {
      flex-flow: row wrap;
      justify-content: space-between;
    }

    h3 {
      flex: 0 0 auto;
      width: 100%;
    }

    div {
      flex: 0 0 auto;
      margin-top: 2rem;
      width: 100%;

      @media #{$mq-m} {
        max-width: 13rem;
      }
    }
  }
}
